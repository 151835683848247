<template>
  <component
    :is="content.component"
    :content="content"
    :class="{
      'v-container': content.contain === true,
      'use-container-md': content.contain === 'md',
    }"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

type BlockComponent = {
  component: string;
  [field: string]: any;
};

export default defineComponent({
  name: "BlockComponent",
  props: {
    content: { type: Object as PropType<BlockComponent>, required: true },
  },
  computed: {
    componentName(): string {
      if (!this.content.component.endsWith("-block")) {
        return this.content.component + "-block";
      }
      return this.content.component;
    },
  },
});
</script>
